import { TbCalendarTime } from 'react-icons/tb'
import { MdContactPhone } from 'react-icons/md'
import { MdOutlineLocationOn } from 'react-icons/md'
import { MdMailOutline } from 'react-icons/md'
import { FaInstagram } from 'react-icons/fa'
import { BsTelephone } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="container-fluid bg-primary">
      <div className="row text-white pb-5">
        <div className="col-12 col-md-4">
          <h5 className="text-center mb-3 text-white fw-bolder mt-5">
            <MdOutlineLocationOn className="mb-1" /> Adres
          </h5>
          <p className="text-center">Cumhuriyet mah. Çalım sok. No:3/3</p>
          <p className="text-center">Alya İş Merkezi Adapazarı/SAKARYA</p>
        </div>
        <div className="col-12 col-md-4 text-center">
          <h5 className="mb-3 text-white fw-bolder mt-5">
            <MdContactPhone className="mb-1" /> İletişim
          </h5>
          <p>
            <BsTelephone className="mb-1" /> &nbsp;
            <a
              href="tel:+905516406510"
              className="text-white text-decoration-none"
            >
              0551 640 65 10
            </a>
          </p>
          <p>
            <MdMailOutline className="mb-1" /> &nbsp;
            <a
              href="mailto:robocorobotikkodlama@gmail.com"
              className="text-white text-decoration-none"
            >
              robocorobotikkodlama@gmail.com
            </a>
          </p>
          <Link
            to="https://www.instagram.com/robocorobotikkodlama/"
            className="text-white text-decoration-none"
          >
            <FaInstagram className="mb-1" /> robocorobotikkodlama
          </Link>
        </div>
        <div className="col-12 col-md-4 text-center">
          <h5 className="mb-3 text-white fw-bolder mt-5">
            <TbCalendarTime /> Çalışma Günleri ve Saatleri
          </h5>
          <p>Salı-Çarşamba-Perşembe-Cuma-Cumartesi</p>
          <p>Saat: 11:00 - 19:00</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
