import Nav from './Nav'
import { Link } from 'react-router-dom'
import { FaInstagram } from 'react-icons/fa'
import { BsPhone } from 'react-icons/bs'
import { MdMailOutline } from 'react-icons/md'
import { MdOutlineLocationOn } from 'react-icons/md'
function Contact() {
  return (
    <div className="vh-100">
      <Nav></Nav>
      <div className="container-fluid bg-light">
        <p className="text-center fs-3 text-blue py-3 mb-0">İLETİŞİM</p>
      </div>
      <div className="container pt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <div className="row pt-4">
              <div className="col-12 col-md-6 mb-4">
                <div className="card w-100 bg-primary text-white border-0">
                  <div className="text-center">
                    <MdOutlineLocationOn className="contact-icon-size mt-3" />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title text-center fw-semibold mb-3">
                      Adres
                    </h5>
                    <div className="card-text">
                      <h6 className="text-center">
                        Cumhuriyet mah. Çalım sok. No:3/3
                      </h6>
                      <h6 className="text-center">
                        Alya İş Merkezi Adapazarı/SAKARYA
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="card w-100 bg-primary text-white border-0">
                  <div className="text-center">
                    <BsPhone className="contact-icon-size mt-4" />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title text-center mb-3 fw-semibold">
                      Telefon
                    </h5>
                    <p className="card-text">
                      <p className="text-center">
                        <a
                          href="tel:+905516406510"
                          className="text-white text-decoration-none"
                        >
                          0551 640 65 10
                        </a>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="card w-100 bg-primary text-white border-0">
                  <div className="text-center">
                    <FaInstagram className="contact-icon-size mt-3" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title text-center mb-3 fw-semibold">
                      Sosyal Medya
                    </h5>
                    <p>
                      <Link
                        to="https://www.instagram.com/robocorobotikkodlama/"
                        className="text-white text-decoration-none"
                      >
                        robocorobotikkodlama
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="card w-100 bg-primary text-white border-0">
                  <div className="text-center">
                    <MdMailOutline className="contact-icon-size mt-3" />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title mb-3 fw-semibold">Mail </h5>
                    <p className="card-text">
                      <p className="text-center">
                        <a
                          href="mailto:robocorobotikkodlama@gmail.com"
                          className="text-white text-decoration-none"
                        >
                          robocorobotikkodlama@gmail.com
                        </a>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12084.691287012289!2d30.3946726!3d40.7802146!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409df7f53a431b35%3A0x525a8a94eafd0209!2sRoboco%20Robotik%20ve%20Kodlama%20At%C3%B6lyesi!5e0!3m2!1str!2str!4v1687534854970!5m2!1str!2str"
                className="w-100"
                title="adres"
                height={490}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
