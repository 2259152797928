import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
import elektronikDevre from '../images/elektronik_devre.jpg'
import kodlambac from '../images/kodlambac.jpg'
import lego from '../images/lego.jpg'
import akilOyunlari from '../images/little_red_riding_hood.jpg'
import matatalab from '../images/matatalab.jpg'
import mindDesignerRobot from '../images/mind_designer_robot.jpg'
import qoboRobot from '../images/qobo.jpg'
import botleyRobot from '../images/botley_robot.jpg'
import robotisDream from '../images/robotis_dream.jpg'
import mTinyRobot from '../images/m-tiny.jpg'
import stemMancinik from '../images/stemMancinik.jpg'
import makeymakey from '../images/makeymakey.jpg'
import kalem3D from '../images/3dpen.jpg'
import tinkercad from '../images/tinkercad.jpg'
import arduino from '../images/arduino.jpg'
function Workshop() {
  return (
    <div>
      <Nav></Nav>
      <div className="container-fluid bg-light">
        <p className="text-center text-blue  fs-3 py-3 mb-0">
          ATÖLYE ÇALIŞMALARI
        </p>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={lego}
                  class="card-img-top workshop-img-height workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                LEGO WEDO 2.0
              </h4>
              <p>
                WeDo 2.0 temel seti, ilkokul öğrencilerinin fen ve bilim
                konularındaki merakını uyandıran, mühendislik, teknoloji ve
                kodlama konularında gerçek hayatta uygulanabilir projelerle
                deneyim kazandıran bir yaparak öğrenme aracıdır.
              </p>
              <p>
                WeDo 2.0 temel seti içinde birbirine kolayca takılabilen
                sensörler, motorlar ve diğer elektronik bileşenler bulunur. Bu
                bileşenlerin birleştirilmesiyle, öğrenciler gerçek hayatta
                karşılaşabilecekleri sorunlara çözümler üretmek için çalışmalar
                yapabilirler.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={robotisDream}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                ROBOTİS DREAM PLAY
              </h4>
              <p>
                Robotis Dream, temel robotik eğitimi sağlayan bir settir. 4
                seviyeden oluşur. İlk seviyede mekanik ve fizik konuları
                öğretilir. İkinci ve üçüncü seviyelerde basit ve sensörlü
                robotlar yapılır ve bu robotlara sensörler, motorlar ve
                işlemciler programlanarak kontrol edilmesi öğretilir. Dördüncü
                seviyede ise uzaktan kumandalı robotlar yapılır ve yarıştırılır.
                Set, 12 farklı tasarım ve programlama ile öğrencilere yeni
                robotlar yapmayı ve becerilerini geliştirmeyi öğretir.
              </p>
              <p>
                Robotis Dream, öğrencilere robotik öğrenmeyi ve bu becerileri
                nerede uygulayabileceklerini anlatır. Aynı zamanda, robotik
                ilkeleri öğrenmelerini, sorunları deneyerek çözmeyi, kendi
                tasarımlarını yapmayı ve programlama becerilerini geliştirmeyi
                hedefler.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={robotisDream}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={mindDesignerRobot}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                MİND DESİNGNER ROBOT
              </h4>
              <p>
                Mind Eğitici Tasarım Robotu, çocukları kodlama ve tasarım
                dünyasına adım atmaya teşvik eden bir araçtır. Çocuklara
                aritmetik ve geometri öğrenme sürecinde rehberlik ederken
                eğlenceli bir deneyim sunar. Ses tanıma özelliği sayesinde
                verilen sesli komutları yerine getirebilir. Çocuklar, aritmetik
                oyun panosunu kullanarak robotu programlayabilir ve robot
                labirentiyle mantıksal düşünme yeteneklerini geliştirirken
                görevleri tamamlayabilirler.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={botleyRobot}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                BOTLEY ROBOT
              </h4>
              <p>
                Botley Robot, okul öncesi gruplara yönelik olarak kodlamayı
                kolay ve dostça bir şekilde tanıtmak amacıyla tasarlanmıştır.
                Çocuklar, Botley sayesinde kod yazmayı öğrenirken aynı zamanda
                eğlenirler.
              </p>
              <p>
                Botley, erken yaşta STEM becerilerinin gelişimine katkı sağlar.
                Ekransız bir şekilde, aktif oyunlarla kodlamanın temellerini
                öğretir. Eleştirel düşünme ve problem çözme yeteneklerini
                geliştirmek için harika bir araçtır. Çocuklar, Botley ile
                interaktif etkileşimler kurarak, mantıksal düşünmeyi ve çözüm
                odaklı düşünmeyi pratiğe dökerler.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={botleyRobot}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={mTinyRobot}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                MTİNY ROBOT
              </h4>
              <p>
                mTiny, okul öncesi çocuklar için tasarlanmış bir eğitim
                robotudur. Kalem şeklindeki kontrolcüsü ile mantıksal düşünmeyi
                ve problem çözme yeteneklerini geliştirir. Kodlama kartları ve
                harita bloklarıyla çocukları etkileşimli oyunlarla öğrenmeye
                teşvik eder. Aynı zamanda matematik, İngilizce ve müzik gibi
                konulara da ilgi uyandırır.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={qoboRobot}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                QOBO ROBOT
              </h4>
              <p>
                Qobo, çocuklara yönelik bir robotik kodlama kitidir. RoboBloq
                Puzzle kartlarıyla programlanabilir ve çocuklara programlamayı
                sevdirmeyi amaçlar. Qobo, kartların üzerindeki işaretlere tepki
                vererek çocuklarla etkileşim kurar. Qobo'nun kullanımı
                çocuğunuzun kodlama becerilerini geliştirebilir. Aynı zamanda
                mantıksal düşünme yetisini geliştirmeye yardımcı olur.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={qoboRobot}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={matatalab}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                MATATALAB
              </h4>
              <p>
                Matatalab, 4-9 yaş grubu çocuklar için tasarlanmış bir kodlama
                eğitim aracıdır. Kod blokları kullanarak oluşturulan dizilerle
                çocuklara kodlamanın kolay olduğunu hissettirir. Matatalab,
                çocukların mantık ve kodlama becerilerini okuma veya bilgisayar
                becerilerine ihtiyaç duymadan sezgisel bir şekilde öğrenmelerini
                sağlar. Bu oyuncak, çocukların oyun oynarken kod yazma
                dinamiklerini keşfetmelerine ve temel becerileri kazanmalarına
                yardımcı olur.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={elektronikDevre}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                ÇIT ÇIT DEVRELER
              </h4>
              <p>
                Bu kit, çocukların elektronik konusunda pratik deneyim
                kazanabilecekleri çıtçıt devreleri içerir. Kolay bir şekilde
                birleştirilebilen plastik modüllerin içinde elemanlar bulunur ve
                kitapçıkta gösterilen örneklerin takibiyle birçok devre
                yapılabilir. Bu sayede devrelerin çalışma prensipleri ve diğer
                bilgiler de öğrenilebilir.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={elektronikDevre}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={makeymakey}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                MAKEY MAKEY{' '}
              </h4>
              <p>
                Makey Makey, çocuklar ve gençler için tasarlanmış bir icat kiti
                olan bir programdır. Makey Makey, basit bir mantıkla çalışır ve
                çocukların yaratıcılığıyla etkileyici sonuçlar elde etmelerini
                sağlar. Elektrik ileten herhangi bir materyali kullanarak farklı
                robotik sonuçlar elde edebilirsiniz. Örneğin, Makey Makey ile
                bilgisayara bağladığınız bir kalem, dokunduğunuz anda
                bilgisayara istediğiniz sinyalleri gönderebilir ve istediğiniz
                materyali klavye veya fare olarak kullanabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={kodlambac}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                KODLAMBAÇ
              </h4>
              <p>
                Kodlambaç, artırılmış gerçeklik teknolojisiyle hazırlanan ilk ve
                tek kodlama oyunudur. Oyunda algoritma ve fonksiyon mantığına
                odaklanılır ve temel kodlama becerileri eğlenceli bir şekilde
                öğretilir. Oyun, hem tek kişiyle oynanabilen bir kitapçık
                formatında sunulur, hem de oyun alanıyla birlikte 2 veya daha
                fazla kişiyle oynanabilir. Oyun, kodlama temelinin sağlam bir
                şekilde oluşturulmasını sağlarken, artırılmış gerçeklik ve oyun
                öğeleriyle desteklenir.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={kodlambac}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={arduino}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                ARDUİNO
              </h4>
              <p>
                Arduino, çeşitli seviyelerdeki insanlara hitap eden bir
                geliştirme kartıdır. Basit entegrasyon ve anlaşılır bir kodlama
                dili kullanır. Arduino, hareketli nesnelerin bağımsız olarak
                çalışmasının yanı sıra bilgisayar yazılımlarıyla da etkileşime
                geçebilir. Çocuklar yaratıcılıklarına bağlı olarak Arduino
                kullanarak yüzlerce proje yapabilirler.
              </p>
              <p>
                Arduino, çocukların teknolojiyle etkileşime geçmelerini ve kendi
                projelerini geliştirmelerini sağlayarak, eğlenceli bir deneyim
                sunar ve önemli beceriler kazandırır.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={kalem3D}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                3D KALEM
              </h4>
              <p>
                3 boyutlu kalemler, çocukların hayal gücünü ve yaratıcılığını
                geliştirmelerine yardımcı olan faydalı araçlardır. Çocuklar, bu
                kalemlerle elleriyle nesneler oluşturarak tasarım becerilerini
                geliştirebilirler. Ayrıca, 3 boyutlu çizim yaparken el-göz
                koordinasyonu, ince motor becerileri ve problem çözme
                yetenekleri de gelişir.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={kalem3D}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={tinkercad}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                3D TASARIM (TİNKERCAD){' '}
              </h4>
              <p>
                3D Tasarım, bilgisayar grafikleri kullanarak canlı veya cansız
                nesnelerin üç boyutlu matematiksel modellerini oluşturmayı
                sağlayan bir süreçtir. Bu yetenek, çocuklar için önemlidir çünkü
                3 boyutlu düşünme, modelleme ve tasarlama becerilerini
                geliştirir. 3D modelleme eğitimi, çocuklara geometri, matematik,
                sanatsal yetenek, mukavemet, planlama, denge gibi konuları
                öğretir ve analitik düşünme becerilerini geliştirir.
              </p>
              <p>
                Ayrıca, bilgisayar kullanımı, ergonomi, malzeme bilgisi gibi
                alanlarda da deneyim kazandırır. 3D Tasarım eğitimi, çocukların
                hayal gücünü artırır ve karşılaşacakları problemleri çözmek için
                kullanabilecekleri bir beceri kazandırır.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none mb-4">
              <div class="card w-100">
                <img
                  src={akilOyunlari}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <h4 className="text-center text-blue fw-semibold mb-4">
                AKIL VE ZEKA OYUNLARI
              </h4>
              <p>
                Akıl ve zeka oyunları, bilgi gerektirmeyen soruları çözerken
                akıl yürütme, hızlı düşünme, yaratıcılık ve ipuçlarından
                faydalanma becerilerini kullanarak oynanan oyunlardır. Bu
                oyunlar, zihni açar, akıl çalıştırır, öğrenmeyi kolaylaştırır ve
                çabuk öğrenmeyi sağlar. "Eğlenirken öğrenme" prensibine dayanan
                akıl ve zeka oyunları, çocukların zeka gelişimine,
                yaratıcılıklarına ve akademik başarılarına katkı sağlar. Bu
                oyunlar sayesinde çocuklar eğlenerek öğrenir ve zekaları
                gelişir.
              </p>
              <p>
                <span>Bu eğitimde, </span>
                <span className="fw-semibold">
                  KIRMIZI BAŞLIKLI KIZ, PENGUEN, TANGRAM, IQ PUZZLE, ASTEROID
                  ESCAPE
                </span>
                <span>
                  {' '}
                  gibi özel tasarlanmış setler kullanılmaktadır. Bu setler,
                  çocukların mantık ve problem çözme becerilerini
                  geliştirmelerine yardımcı olur.
                </span>
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div class="card w-100">
                <img
                  src={akilOyunlari}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4">
              <div class="card w-100">
                <img
                  src={stemMancinik}
                  class="card-img-top workshop-img-height"
                  alt="ders"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <h4 className="text-center text-blue fw-semibold mb-4">
                STEM SETLERİ
              </h4>
              <p>
                STEM (Science, Technology, Engineering, and Mathematics)
                eğitimi, çocukların problem çözme, yaratıcılık, eleştirel
                düşünme ve işbirliği becerilerini geliştirmelerine yardımcı olan
                bir eğitim yaklaşımıdır. Bu eğitimle çocuklar, gerçek hayattaki
                sorunları çözmek için bilimsel ve matematiksel prensipleri
                kullanmayı öğrenirler.
              </p>
              <p>
                <span>Bu eğitimde, </span>
                <span className="fw-semibold">
                  MANCINIK, VIZIR TEL, GECE LAMBASI, ZIP ZIP ROBOT, YEL
                  DEĞİRMENİ
                </span>
                <span>
                  {' '}
                  gibi özel tasarlanmış setler kullanılmaktadır. Bu setler,
                  çocukları fen, teknoloji, mühendislik ve matematik alanlarıyla
                  etkileşime sokar. Bu sayede çocuklar, eğlenceli ve interaktif
                  bir şekilde temel STEM prensiplerini öğrenirken, hayal
                  güçlerini ve yaratıcılıklarını da geliştirirler.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default Workshop
