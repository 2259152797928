import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
import ahsap from '../images/workshopAhsap.jpg'
import lego from '../images/workshopLego.jpg'
import stem from '../images/workshopStem.jpg'
function Event() {
  return (
    <div>
      <Nav></Nav>
      <div className="container-fluid bg-light">
        <p className="text-center fs-3 text-blue py-3 mb-0">
          ETKİNLİKLERİMİZ / WORKSHOP
        </p>
      </div>
      <div className="container-fluid bg-white mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 align-self-center mt-2 mt-md-0 mb-4">
              <div className="card w-100 bg-success text-white shadow-lg border-0">
                <h5 className="card-title text-center fs-3 my-4">AHŞAP</h5>
                <img
                  src={ahsap}
                  className="card-img-top event-img-height"
                  alt="robotik"
                />
                <div className="card-body">
                  <p className="text-center mt-3">
                    Ahşap Eğitimi workshopunda çocuklar, ahşap malzemelerle
                    farklı tasarımlar yaparak el becerilerini geliştirebilirler.
                    Bu workshop sayesinde, çocuklar doğa ile uyumlu malzemeler
                    kullanarak özgün tasarımlar yapmayı öğrenirler. Aynı zamanda
                    işbirliği ve yardımlaşmayı da öğrenirler.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="card w-100 bg-warning text-white shadow-lg border-0">
                <h5 className="card-title text-center fs-3 my-4">STEM</h5>
                <img
                  src={stem}
                  className="card-img-top event-img-height"
                  alt="robotik"
                />
                <div className="card-body">
                  <p className="text-center mt-3">
                    STEM Eğitimi workshopunda çocuklar, fen, teknoloji,
                    mühendislik ve matematik becerilerini geliştirirler. Bu
                    workshoplarda, çocuklar basit elektrik devrelerinden robot
                    tasarımlarına kadar birçok uygulama yapabilirler. Bu sayede,
                    çocuklar problem çözme, eleştirel düşünme, yaratıcılık ve
                    işbirliği becerilerini geliştirirler.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="card w-100 bg-danger text-white shadow-lg border-0">
                <h5 className="card-title text-center fs-3 my-4">LEGO</h5>
                <img
                  src={lego}
                  className="card-img-top event-img-height"
                  alt="robotik"
                />
                <div className="card-body">
                  <p className="text-center mt-3">
                    Robotik Kodlama Eğitimi workshopunda ise çocuklar, robot
                    tasarımı ve kodlama konusunda bilgi sahibi olurlar. Bu
                    workshoplarda, çocuklar birçok farklı robot tasarlayıp,
                    programlama becerilerini geliştirerek, kendi robotlarını
                    hayata geçirebilirler. Kodlama ile robot kontrol etmeyi
                    öğrenirler.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-align-justify">
            Roboco Robotik Kodlama Atölyesi, çocukların STEM (Fen, Teknoloji,
            Mühendislik ve Matematik) becerilerini geliştirmek,
            yaratıcılıklarını ortaya çıkarmak ve eğlenirken öğrenmelerini
            sağlamak amacıyla çeşitli workshoplar düzenlemektedir.
          </p>
          <p className="text-align-justify mt-2">
            Roboco Robotik Kodlama Atölyesi workshopları, çocukların öğrenirken
            eğlenmelerini sağlayarak, onların ilgi alanlarını keşfetmelerine,
            tutkularını ve yeteneklerini geliştirmelerine yardımcı olur.
          </p>
          <p className="text-center mt-5 fw-bolder">
            Etkinliklerimiz ile ilgili detaylı bilgi almak için iletişime
            geçiniz.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Event
