import Nav from './Nav'
import Footer from './Footer'
import carouselImg1 from '../images/slider2.jpg'
import carouselImg2 from '../images/slider3.jpg'
import robotik from '../images/robotik.jpg'
import kodlama from '../images/kodlama.jpg'
import stem from '../images/stem.jpg'
import akilOyunlari from '../images/akil_oyunlari.jpg'
import technology from '../images/technology.png'
import carrier from '../images/carrier.png'
import funnyLearn from '../images/funny_learn.png'
import partnership from '../images/partnership.png'
import freedom from '../images/freedom.png'
import problemSolving from '../images/problem_solving.png'
import machineLearn from '../images/machine_learn.png'
import focus from '../images/focus.png'
import creativeThinking from '../images/creative_thinking.png'
import causeEffect from '../images/cause_effect.png'
import officeimg4 from '../images/1.jpg'
import officeimg2 from '../images/2.jpg'
import officeimg3 from '../images/3.jpg'
import officeimg1 from '../images/4.jpg'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <>
      <Nav />
      <div className="container-fluid bg-light m-0 p-0">
        <div
          id="carouselExampleDark"
          className="carousel carousel-light slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active " data-bs-interval="10000">
              <img
                src={carouselImg1}
                className="d-block w-100 carousel-img"
                alt="roboco"
              />
              <div className="carousel-caption d-none d-md-block carousel-linear-gradient">
                <h1>Roboco Robotik Kodlama Atölyesi</h1>
                <p>
                  Robotik, Kodlama, Maker, Stem, Akıl Oyunları, 3D Tasarım,
                  Elektrik-Elektronik Eğitimi ve çok daha fazlası!
                </p>
              </div>
              <div className="carousel-caption d-block d-md-none carousel-linear-gradient">
                <p className="fs-3">Roboco Robotik Kodlama Atölyesi</p>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="10000">
              <img
                src={carouselImg2}
                className="d-block w-100 carousel-img"
                alt="etkinlik"
              />
              <div className="carousel-caption d-none d-md-block carousel-linear-gradient">
                <h1>Etkinliklerimiz</h1>
                <p>
                  Yaz kampı, kış kampı ve workshop programları hakkında detaylı
                  bilgi almak için iletişime geçiniz.
                </p>
              </div>
              <div className="carousel-caption d-block d-md-none carousel-linear-gradient">
                <p className='px-2'>
                  Robotik, Kodlama, Maker, Stem, Akıl Oyunları, 3D Tasarım,
                  Elektrik-Elektronik Eğitimi ve çok daha fazlası!
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="container-fluid bg-white py-5 ">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-6 col-lg-3 mb-2">
                <Link to="/atolye-calismalari" className='text-white text-decoration-none'>
                <div className="card w-100 bg-primary text-white shadow-lg border-0">
                  <img src={robotik} className="card-img-top" alt="robotik" />
                  <div className="card-body">
                    <h5 className="card-title text-center fs-1 my-5">
                      Robotik
                    </h5>
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-2">
              <Link to="/atolye-calismalari" className='text-white text-decoration-none'>
                <div className="card w-100 bg-danger text-white shadow-lg border-0">
                  <img
                    src={akilOyunlari}
                    className="card-img-top"
                    alt="robotik"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center fs-1 my-5">
                      Akıl Oyunları
                    </h5>
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-2">
              <Link to="/atolye-calismalari" className='text-white text-decoration-none'>
                <div className="card w-100 bg-warning text-white shadow-lg border-0">
                  <img src={kodlama} className="card-img-top" alt="robotik" />
                  <div className="card-body">
                    <h5 className="card-title text-center fs-1 my-5">
                      Kodlama
                    </h5>
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-2">
              <Link to="/atolye-calismalari" className='text-white text-decoration-none'>
                <div className="card w-100 bg-success text-white shadow-lg border-0">
                  <img src={stem} className="card-img-top" alt="robotik" />
                  <div className="card-body">
                    <h5 className="card-title text-center fs-1 my-5">STEM</h5>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-5">
          <div className="row">
            <p className="fs-2 mb-5 text-center">Neden ROBOCO?</p>
            <div className="col-12 col-md-6 d-none d-lg-block text-end">
              <div>
                <span className="ms-4">Teknoloji Odaklı Eğitim</span>
                <img src={technology} alt="teknoloji" />
              </div>
              <div>
                <span className="ms-4 ">Öğrenmeyi Eğlenceli Hale Getirme</span>
                <img src={funnyLearn}  alt="eğlenceli öğrenme" />
              </div>
              <div>
                <span className="ms-4">Özgürlük ve Yaratıcılık</span>
                <img src={freedom} alt="özgürlük ve yaratıcılık" />
              </div>
              <div>
                <span className="ms-4"> İşbirliği Yapma Fırsatı</span>
                <img src={partnership} alt="işbirliği" />
              </div>
              <div>
                <span className="ms-4" >Gelecekteki Kariyer İmkanları</span>
                <img src={carrier}   alt="kariyer" />
              </div>
            </div>
            <div className="col-12 col-md-6 d-block d-lg-none">
              <div>
                <img src={technology} className='icon-img-size-sm' alt="teknoloji" />
                <span className="ms-4 icon-text-size-sm">Teknoloji Odaklı Eğitim</span>
              </div>
              <div>
                <img src={funnyLearn}  className='icon-img-size-sm' alt="eğlenceli öğrenme" />
                <span className="ms-4 icon-text-size-sm">Öğrenmeyi Eğlenceli Hale Getirme</span>
              </div>
              <div>
                <img src={freedom} className='icon-img-size-sm' alt="özgürlük ve yaratıcılık" />
                <span className="ms-4 icon-text-size-sm">Özgürlük ve Yaratıcılık</span>
              </div>
              <div>
                <img src={partnership} className='icon-img-size-sm' alt="işbirliği" />
                <span className="ms-4 icon-text-size-sm"> İşbirliği Yapma Fırsatı</span>
              </div>
              <div>
                <img src={carrier} className='icon-img-size-sm' alt="kariyer" />
                <span className="ms-4 icon-text-size-sm">Gelecekteki Kariyer İmkanları</span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <img src={problemSolving} className='icon-img-size-sm' alt="problem çözme" />
                <span className="ms-4 icon-text-size-sm">Problem Çözme Becerisi</span>
              </div>
              <div>
                <img src={creativeThinking} className='icon-img-size-sm' alt="yaratıcı ve sitematik " />
                <span className="ms-4 icon-text-size-sm">Yaratıcı ve Sistematik Düşünme</span>
              </div>
              <div>
                <img src={causeEffect} className='icon-img-size-sm' alt="neden-sonuç" />
                <span className="ms-4 icon-text-size-sm">Neden-Sonuç İlişkisi Kurabilme</span>
              </div>
              <div>
                <img src={machineLearn} className='icon-img-size-sm' alt="makinelerin çalışma mantığı" />
                <span className="ms-4 icon-text-size-sm">
                  Makinelerin Çalışma Mantığını Anlama
                </span>
              </div>
              <div>
                <img src={focus} className='icon-img-size-sm' alt="odak ve dikkat" />
                <span className="ms-4 icon-text-size-sm">Odaklanma ve Dikkat Geliştirme</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-white">
          <div className="container pb-5 pt-5">
            <p className="text-center fs-2">Atölyemiz</p>
            <div className="row justify-content-center">
              <div className="col-12 col-md-3 mb-3">
                <div className="card text-bg-light">
                  <img
                    src={officeimg1}
                    className="card-img atolye-img"
                    alt="atölye"
                  />
                </div>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <div className="card text-bg-light">
                  <img
                    src={officeimg2}
                    className="card-img atolye-img"
                    alt="atölye"
                  />
                </div>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <div className="card text-bg-light">
                  <img
                    src={officeimg3}
                    className="card-img atolye-img"
                    alt="atölye"
                  />
                </div>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <div className="card text-bg-light">
                  <img
                    src={officeimg4}
                    className="card-img atolye-img"
                    alt="atölye"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
