import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Contact from './components/Contact'
import About from './components/About'
import Workshop from './components/Workshop'
import Event from './components/Event'
import Home from './components/Home'
import NoMatch from './components/NoMatch'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/atolye-calismalari" element={<Workshop />} />
          <Route path="/etkinliklerimiz" element={<Event />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
