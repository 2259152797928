import { Link } from 'react-router-dom'
import logo from '../images/roboco.svg'
function Nav() {
  return (
    <div className='container-fluid bg-white'>
      <div className="container">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="logo" width="200" height="90" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse flex-grow-0"
              id="navbarScroll"
            >
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ">
                <li>
                  <Link
                    to="/"
                    className="nav-link active fs-6 me-4"
                    aria-current="page"
                  >
                    Anasayfa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/hakkimizda" className="nav-link fs-6 me-4">
                    Hakkımızda
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/atolye-calismalari" className="nav-link fs-6 me-4">
                    Atölye Çalışmaları
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/etkinliklerimiz" className="nav-link fs-6 me-4">
                    Etkinliklerimiz
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/iletisim" className="nav-link fs-6">
                    İletişim
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Nav
