import React from 'react'
import Nav from './Nav'
import Footer from './Footer'
import aboutimg1 from '../images/aboutimg1.jpg'
import aboutimg2 from '../images/aboutimg2.jpg'
import child1 from '../images/child1.jpg'
import child2 from '../images/child2.jpg'
import child3 from '../images/child3.jpg'
import child4 from '../images/child4.jpg'
import child5 from '../images/child5.jpg'
import child6 from '../images/child6.jpg'
import child7 from '../images/child7.jpg'
import child8 from '../images/child8.jpg'
import child9 from '../images/child9.jpg'
import child10 from '../images/child10.jpg'
import child11 from '../images/child11.jpg'
import child12 from '../images/child12.jpg'
import { FcApproval } from 'react-icons/fc'
function About() {
  return (
    <div>
      <Nav></Nav>
      <div className="container-fluid bg-light ">
        <p className="text-center fs-3 text-blue py-3 mb-0">HAKKIMIZDA</p>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <div className="row justify-content-center py-5">
            <div className="col-12 col-md-4">
              <div className="card w-100">
                <img src={aboutimg1} className="card-img-top" alt="about" />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center mt-4 mt-md-0 ">
              <p>
                2022 yılında Sakarya’da faaliyete başlayan &nbsp;
                <span className="fw-semibold">
                  Roboco Robotik ve Kodlama atölyesi &nbsp;
                </span>
                olarak, dijitalleşen dünyada çocuklarımıza, teknolojiyi en doğru
                şekilde kullanmayı, bilişimi yakından takip etmeyi öğreterek;
                analitik ve uzamsal düşünme becerileriyle geleceğin dili olan
                robotik ve kodlama alanında onların potansiyellerini ortaya
                çıkarmayı hedeflemekteyiz.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container">
          <div className="row py-5 justify-content-center">
            <div className="col-12 col-md-4 d-block d-md-none">
              <div className="card w-100 mb-4">
                <img src={aboutimg2} className="card-img-top" alt="about" />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <p>
                Öğrencilerimize özgün ve yaratıcı düşünme kabiliyetiyle yeni
                bakış açıları kazandırmak amacıyla mevcut imkanlarımızı hep
                ileriye taşıyıp, kendilerini keşfetmeleri için fırsatlar
                sunmaktayız.
              </p>
            </div>
            <div className="col-12 col-md-4 d-none d-md-block">
              <div className="card w-100">
                <img src={aboutimg2} className="card-img-top" alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container py-5">
          <p className="text-center mb-4 fw-semibold">
            Materyallerimiz ve uyguladığımız güncel müfredatımız sayesinde
            öğrencilerimize;
          </p>
          <div className="row">
            <div className="col-12 col-md-4"></div>
            <div className="col-12 col-md-5">
              <p>
                <FcApproval className="fs-6 mb-1" /> 3 boyutlu modelleme ve
                düşünme becerisi
              </p>
              <p>
                <FcApproval className="fs-6 mb-1" /> Analitik düşünme becerisi
              </p>
              <p>
                <FcApproval className="fs-6 mb-1" /> Olay ve olgulara yeni bakış
                açıları oluşturma
              </p>
              <p>
                <FcApproval className="fs-6 mb-1" /> Sebep- sonuç ilişkisi
                kurabilme
              </p>
              <p>
                <FcApproval className="fs-6 mb-1" /> Odaklanma ve dikkat
                becerisini geliştirme
              </p>
              <p>
                <FcApproval className="fs-6 mb-1" /> Bilgiyi doğru ve dolaysız
                aktarabilme
              </p>
              <p>
                <FcApproval className="fs-6 mb-1" /> İş birliği ve ortak çalışma
                becerisi, kazandırmayı ilke edindik.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="container pb-5 pt-5">
          <p className="text-center fs-2 mb-5">Derslerimizden Kareler</p>
          <div className="row justify-content-center">
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child1}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child2}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child6}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child7}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child5}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child10}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child4}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child8}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child9}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child3}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child11}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
            <div className="col-6 col-md-3 mb-4">
              <div className="card text-bg-light">
                <img
                  src={child12}
                  className="card-img w-100 img-lesson"
                  height={250}
                  alt="dersler"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default About
